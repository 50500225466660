import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Modal } from "../../../../components";
import ApiTokenForm, { IApiTokenFormInitParams } from "../ApiTokenForm";
import { getActiveOrganization } from "../../../../redux/selectors";
import { ApiTokenService, ToastService } from "../../../../services";
import { ApiTokenModel } from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";
import { CloseCircleIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";

const ApiTokenCreate = () => {
  const organization = useSelector(getActiveOrganization);
  const [createForm, setCreateForm] = useState<IApiTokenFormInitParams>({
    getFormData: () => null,
  });
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);
  const [permissionsTouched, setPermissionsTouched] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const history = useHistory();

  const navigateBack = () => {
    history.push(ROUTES.DEVELOPER.API_TOKENS.INDEX);
  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ApiTokenService.create(organization.uuid, {
        ...data,
        org_uuid: organization.uuid,
      })
        .then((data) => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> created
              new API Token.
            </>
          );
          navigateBack();
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Creating api token failed");
        });
    }
  };

  return (
    <>
      <div className="relative z-100 w-full h-full flex flex-col">
        <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
          <div className="font-semibold text-lg uppercase pt-3 pb-2">
            Create Api Token
          </div>
          <CloseCircleIcon
            color="white"
            size={32}
            className="cursor-pointer"
            onClick={() => setShowConfirmation(true)}
          />
        </div>
        <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
          <Card>
            <div className=" mx-auto my-6">
              <ApiTokenForm
                onInit={setCreateForm}
                onClose={() => {
                  navigateBack();
                }}
                setDisableSaveButton={setDisableSaveButton}
                setTouched={setTouched}
                setPermissionsTouched={setPermissionsTouched}
              />
            </div>
          </Card>
        </div>
        <div className="flex justify-between p-6 space-x-4">
          <Button
            data-cy="create-token"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onCreate}
            disabled={disableSaveButton}
          >
            Create Token
          </Button>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={
            <p className="text-base">
              You have <span className="font-bold">unsaved changes.</span> Are
              you sure you would like to cancel and close this confirmation
              window?
            </p>
          }
          onClose={() => navigateBack()}
        />
      )}
    </>
  );
};

export default ApiTokenCreate;
