import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import { Card } from "@mui/material";
import { Button, Input } from "../../../../components";
import BlockedNumberForm, {
  IBlockedNumberFormInitParams,
} from "../BlockedNumberForm";
import { BlockedNumberModel } from "../../../../utils/types";
import { BLOCKED_NUMBER_CHANNEL } from "../../../../utils/enums";
import { MessageBoxService, ToastService } from "../../../../services";
import { BlockedNumberService } from "../../../../services/blocked-number.service";

const BlockedNumberEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [blockedNumber, setBlockedNumber] = useState<BlockedNumberModel>();

  React.useEffect(() => {
    if (id) {
      BlockedNumberService.get(id, true)
        .then((data) => {
          setBlockedNumber(data);
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Failed to load blocked number.");
          onClose();
        });
    }
  }, [id]);

  const [editForm, setEditForm] = useState<IBlockedNumberFormInitParams>({
    getFormData: () => null,
  });

  const onClose = (bypass?: boolean) => {
    history.push(ROUTES.SETTINGS.BLOCKED_NUMBERS.INDEX);
  };

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      Object.assign(blockedNumber, data);
      blockedNumber
        .edit()
        .then(() => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> saved your
              changes.
            </>
          );
          onClose();
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Edit failed.");
        });
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: "passcode",
      title: "Delete confirmation",
      message: <>Are you sure you want to remove this number from spam?</>,
      passcode: "Delete",
    }).then((result) => {
      if (result) {
        blockedNumber
          .delete()
          .then(() => {
            ToastService.success(
              <>
                <span className="text-success mr-1">Successfully</span> removed
                from spam.
              </>
            );
            onClose();
          })

          .catch((err) => {
            ToastService.showHttpError(err, "Delete failed.");
          });
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Edit Blocked Number
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => onClose()}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
        <Card>
          <BlockedNumberForm
            blockedNumber={blockedNumber}
            onChange={setEditForm}
          />
        </Card>
      </div>
      <div className="flex justify-between p-6 space-x-4">
        <Button
          className="bg-blue rounded-full text-white px-6 py-2 text-sm"
          onClick={onDelete}
        >
          UNBLOCK NUMBER
        </Button>
        <Button
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          onClick={onSave}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default BlockedNumberEdit;
