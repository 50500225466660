import React, { FC } from "react";
import { Modal } from "../../../components";
import CallDetail from "../CallDetail";
import { CallRecordModel } from "../../../utils/types";

interface ICallDetailModalProps {
  call: CallRecordModel;
  onClose: () => void;
}

const CallDetailModal: FC<ICallDetailModalProps> = ({ call, onClose }) => {
  return (
    <Modal
      title={`Call / ${call.uuid}`}
      size="full"
      contentClass="bg-gray-f6 lg:px-18 lg:py-9"
      onClose={onClose}
    >
      <CallDetail call={call} />
    </Modal>
  );
};

export default CallDetailModal;
