import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../../constants";
import BlockedNumbers from "./index";
import BlockedNumberEdit from "./BlockedNumberEdit";
import BlockedNumberCreate from "./BlockedNumberCreate";

const BlockedNumbersRouting = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_NUMBERS.CREATE}
      component={BlockedNumberCreate}
    />
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_NUMBERS.EDIT}
      component={BlockedNumberEdit}
    />
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_NUMBERS.PREFIX}
      component={BlockedNumbers}
    />
  </Switch>
);

export default BlockedNumbersRouting;
