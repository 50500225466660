import { BLOCKED_KEYWORD_CHANNEL, BLOCKED_KEYWORD_TYPE } from "../enums";

export class BlockedKeywordModel {
  uuid: string;
  keywords: string;
  type: BLOCKED_KEYWORD_TYPE;
  channels: BLOCKED_KEYWORD_CHANNEL[];
  blockedBy: string;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      keywords: "",
      type: "",
      channels: [],
      blockedBy: "",
      ...init,
    };

    this.uuid = data.uuid;
    this.keywords = data.keywords;
    this.type = data.type;
    this.channels = data.channels;
    this.blockedBy = data.blockedBy;
  }
}
