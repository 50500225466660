import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Chip, Chips, Input } from "../../../../components";
import {
  Form,
  FormControl,
  useForm,
  Validators,
} from "../../../../components/ReactiveForm";
import { ApiTokenModel } from "../../../../utils/types";
import { API_TOKEN_PERMISSIONS } from "../../../../constants";
import _ from "lodash";

export interface IApiTokenFormInitParams {
  getFormData: () => {
    name: string;
    permissions: string[];
  };
}

type AccessType = "Full Access" | "View Only";
const accessOptions: AccessType[] = ["Full Access", "View Only"];

export interface IApiTokenFormProps {
  apiToken?: ApiTokenModel;
  setDisableSaveButton?: Dispatch<SetStateAction<boolean>>;
  setTouched?: any;
  setPermissionsTouched?: any;
  onInit?: (params: IApiTokenFormInitParams) => void;
  onClose?: () => void;
}

const ApiTokenForm: FC<IApiTokenFormProps> = ({
  apiToken,
  setTouched,
  setPermissionsTouched,
  onInit = () => {},
  setDisableSaveButton = () => {},
  onClose = () => {},
}) => {
  const [form] = useForm<{
    name: FormControl;
  }>({
    name: new FormControl("", [Validators.required()]),
  });
  const [initialForm, setInitialForm] = useState(form.getFormData());
  const [permissions, setPermissions] = useState(
    API_TOKEN_PERMISSIONS.map((item) => ({
      ...item,
      access: null,
    }))
  );
  const [initialPermissions, setInitialPermissions] = useState(permissions);

  if (setTouched) setTouched(!_.isEqual(initialForm, form.getFormData()));
  if (setPermissionsTouched)
    setPermissionsTouched(!_.isEqual(initialPermissions, permissions));

  useEffect(() => {
    if (apiToken) {
      form.patch({
        name: apiToken.name,
      });
      initialForm.name = apiToken.name;
      setPermissions(
        API_TOKEN_PERMISSIONS.map((item) => ({
          ...item,
          access: apiToken.permissions.includes(item.values[0])
            ? "Full Access"
            : apiToken.permissions.includes(item.values[1])
            ? "View Only"
            : "No Access",
        }))
      );
      setInitialPermissions(
        API_TOKEN_PERMISSIONS.map((item) => ({
          ...item,
          access: apiToken.permissions.includes(item.values[0])
            ? "Full Access"
            : apiToken.permissions.includes(item.values[1])
            ? "View Only"
            : "No Access",
        }))
      );
    }
  }, [apiToken]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return {
        ...form.getFormData(),
        permissions: permissions
          .filter((item) => ["Full Access", "View Only"].includes(item.access))
          .map((item) =>
            "Full Access" === item.access ? item.values[0] : item.values[1]
          ),
      };
    }
    return null;
  }, [form, permissions]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  const onPermissionChange = (permission, access: AccessType) => {
    setPermissions(
      permissions.map((item) =>
        item.name === permission.name ? { ...item, access } : item
      )
    );
    if (permissions) {
      setDisableSaveButton(false);
    }
  };

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        fullWidth
        label="Give your token a name"
        labelClass="text-md font-semibold"
        placeholder="Give the token a cool name to help you find it in logs and debugging"
      />

      <div className="text-blue-dark">
        <div className="font-semibold">Permissions</div>

        <p className="text-sm">
          Set the functions allowed by this API token by selecting which types
          of requests this API token is allowed to make
        </p>

        <table className="table border-collapse w-full mt-4">
          <thead>
            <tr className="bg-gray-da text-left">
              <th className="px-4 py-1">Services</th>
              <th className="px-4 py-1">Permissions</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission) => (
              <tr key={permission.name}>
                <td className="px-4 py-2 border-l border-b border-gray-da">
                  {permission.name}
                </td>
                <td
                  data-cy={`permission-group-${permission.name
                    .toLowerCase()
                    .replace(/ /g, "")}`}
                  className="px-4 py-2 border-r border-b border-gray-da"
                >
                  <Chips>
                    {accessOptions.map((option, i) => (
                      <Chip
                        key={i}
                        containerClass="mr-2 mb-0"
                        active={permission.access === option}
                        onClick={() => onPermissionChange(permission, option)}
                      >
                        {option}
                      </Chip>
                    ))}
                  </Chips>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Form>
  );
};

export default ApiTokenForm;
