import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import { Card, Chip, Chips, TextField } from "../../../components";
import {
  CoinsIcon,
  LogoIcon,
  PhoneContactIcon,
  PhoneIcon,
  PhoneRingIcon,
  PhoneVideoCallIcon,
  TimeIcon,
  TimeIntervalIcon,
} from "../../../components/icons";
import { AddTagModal, FullAudioPlayer, IExtraInfo } from "../../../parts";
import { LogService, MessageBoxService, ToastService } from "../../../services";
import {
  copyToClipboard,
  formatPhoneNumber,
  getCountryCodeFromNumber,
} from "../../../utils/helpers";
import { CallRecordModel, VoiceCallLogModel } from "../../../utils/types";
import { CALL_DIRECTION, CALL_STATUS } from "../../../utils/enums";

export interface ICallDetailProps {
  call: CallRecordModel;
}

const CallDetail: FC<ICallDetailProps> = ({ call }) => {
  const [callLog, setCallLog] = useState<VoiceCallLogModel>();
  const [tags, setTags] = useState<string[]>([
    "Important",
    "Follow up Required",
  ]);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);
  const [webhooks, setWebhooks] = useState<string[]>([]);
  const [features, setFeatures] = useState<string[]>([]);

  useEffect(() => {
    LogService.searchVoiceCallLogs({ uuid: call.uuid })
      .then((data) => {
        const log = data[0];
        setCallLog(log);

        if (log) {
          setWebhooks(log.webhooks);
          setFeatures(log.features);
        }
      })
      .catch((err) => {
        ToastService.showHttpError(err, "Loading call detail failed");
      });
  }, [call]);

  const onAddTags = (newTags: []) => {
    setTags([...tags, ...newTags]);
  };

  const onRemoveTag = (index: number) => {
    MessageBoxService.confirm({
      title: "Delete confirmation",
      message: (
        <>
          Are you sure you want to delete <b>'{tags[index]}'</b> tag?
        </>
      ),
    }).then((result) => {
      if (result) {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
      }
    });
  };

  return (
    <>
      <div className="w-full h-full space-x-4 flex flex-row">
        <Card className="h-full overflow-y-auto ">
          <div className="text-md font-semibold">Call Details</div>
          {call.call_direction !== CALL_DIRECTION.INBOUND && (
            <div
              className="text-11p hover:text-blue-light  has-hover-action cursor-pointer"
              onClick={() => copyToClipboard(callLog?.ip_address || "")}
            >
              <span className="font-semibold transition-all">IP Address</span>
              <span className="transition-all">
                : {callLog?.ip_address || ""}
              </span>
              <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
            </div>
          )}
          <div
            className="text-11p hover:text-blue-light  has-hover-action cursor-pointer"
            onClick={() => copyToClipboard(call.uuid)}
          >
            <span className="font-semibold transition-all">UUID</span>
            <span className="transition-all">: {call.uuid}</span>
            <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
          </div>

          <div className="flex flex-col space-y-2 pt-10">
            <div className="flex flex-col py-2 px-4 space-y-1 bg-blue-lighter rounded-lg">
              <span className="text-xs text-blue">
                {call.status === CALL_STATUS.BLOCKED_NUMBER
                  ? "Blocked Caller"
                  : "Caller No:"}
              </span>
              <div className="flex flex-row ">
                <div className="">
                  <img
                    src={`/assets/images/flags/${getCountryCodeFromNumber(
                      call.from_number
                    )?.toLowerCase()}.png`}
                    alt=""
                    width={18}
                    height={5}
                  />
                </div>
                <span className="ml-2 text-sm font-semibold text-blue">
                  {formatPhoneNumber(call.from_number)}
                </span>
              </div>
            </div>

            <div className="flex flex-col py-2 px-4 space-y-1 bg-blue-dark bg-opacity-20 rounded-lg">
              <span className="text-xs text-blue-dark">Sudo Number</span>
              <div className="flex flex-row ">
                <div className="">
                  <img
                    src={`/assets/images/flags/${getCountryCodeFromNumber(
                      call.number
                    ).toLowerCase()}.png`}
                    alt=""
                    width={18}
                    height={5}
                  />
                </div>
                <span className="ml-2 text-sm font-semibold text-blue-dark">
                  {formatPhoneNumber(call.number)}
                </span>
              </div>
            </div>

            <div className="flex flex-col py-2 px-4 space-y-1 bg-primary bg-opacity-19 rounded-lg">
              <span className="text-xs text-primary">Target Number</span>
              <div className="flex flex-row ">
                <div className="">
                  <img
                    src={`/assets/images/flags/${getCountryCodeFromNumber(
                      call.target_number
                    ).toLowerCase()}.png`}
                    alt=""
                    width={18}
                    height={5}
                  />
                </div>
                <span className="ml-2 text-sm font-semibold text-primary">
                  {formatPhoneNumber(call.target_number)}
                </span>
              </div>
            </div>

            <div className="flex flex-row space-x-2 pt-8">
              <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-2 flex-1">
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-gray-f8">
                  <TimeIcon color="blue" size={15} />
                </div>
                <div className="text-xs mt-1">Date</div>
                <div className="text-blue-light text-xs">
                  <span>{call.call_start_stamp.substr(0, 10)}</span>&nbsp;
                  <span className="font-semibold">
                    {call.call_start_stamp.substr(11, 8)}
                  </span>
                </div>
              </div>

              <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-2 flex-1">
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-gray-f8">
                  <TimeIntervalIcon color="blue" size={15} />
                </div>
                <div className="text-xs mt-1">Duration</div>
                <div className="text-blue-light text-xs">
                  <span>{call.formatDuration()}</span>&nbsp;
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-2 pt-8">
              <div
                className={classnames(
                  "relative flex flex-col items-center text-center bg-primary-lighter text-primary rounded-md p-2 flex-1",
                  {
                    "!bg-[#ecf5dd] !text-success":
                      call.status === CALL_STATUS.ANSWERED,
                  },
                  {
                    "!bg-[#fed8da] !text-danger-light":
                      call.status === CALL_STATUS.BLOCKED_NUMBER,
                  }
                )}
              >
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-inherit">
                  <PhoneIcon size={15} />
                </div>
                <div className="text-xs mt-1">Call Status</div>
                <div className="text-xs">
                  {call.status === CALL_STATUS.BLOCKED_NUMBER
                    ? "Blocked Number"
                    : call.status}
                </div>
              </div>

              <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-2 flex-1">
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-gray-f8">
                  <PhoneRingIcon color="blue" size={15} />
                </div>
                <div className="text-xs mt-1">Ring Time</div>
                <div className="text-blue-light text-xs">
                  <span>{call.formatRingTime()}</span>
                  &nbsp;
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-2 pt-8">
              <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-2 flex-1">
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-gray-f8">
                  <CoinsIcon color="blue" size={15} />
                </div>
                <div className="text-xs mt-1">Amount</div>
                <div className="text-blue-light text-xs">
                  <span>{call.formatAmount() || "-"}</span>
                  &nbsp;
                </div>
              </div>

              <div className="flex bg-white p-2 flex-1"></div>
            </div>
          </div>
        </Card>
        <div className="flex flex-col space-y-2 w-2/3">
          <Card>
            <div className="flex flex-col w-full">
              <div className="flex justify-between">
                <span className="text-md font-semibold">Call Recording</span>
                <div className="flex flex-row">
                  <div className="flex items-center">
                    <i className="far fa-circle text-blue text-sm" />
                    <span className="text-15p ml-2">Caller</span>
                  </div>
                  <div className="flex items-center ml-8">
                    <i className="far fa-circle text-primary text-sm" />
                    <span className="text-15p ml-2">Target</span>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-300 my-4"></div>

              {call.voice_recording_url ? (
                <FullAudioPlayer
                  name={call.uuid}
                  src={call.voice_recording_url}
                  stereo
                />
              ) : (
                <div className="bg-info-lighter text-blue-dark font-semibold rounded-md shadow-md px-6 py-4 mb-4.5">
                  This call has no call recording record.
                </div>
              )}
            </div>
          </Card>

          {!!Object.keys(call.meta).length && (
            <Card
              title="Extra Info"
              headerClass="text-blue-dark text-lg font-semibold px-1"
            >
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(call.meta).map((info, i) => (
                  <TextField
                    key={i}
                    containerClass="mb-5"
                    label={info[0]}
                    value={info[1]}
                    readonly
                  />
                ))}
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default CallDetail;
