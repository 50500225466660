import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Chip, Chips, Input } from "../../../../components";
import {
  Form,
  FormControl,
  useForm,
  Validators,
} from "../../../../components/ReactiveForm";
import { getActiveOrganization } from "../../../../redux/selectors";
import {
  ProjectService,
  ToastService,
  UserService,
} from "../../../../services";
import { ROLE } from "../../../../utils/enums";
import {
  ProjectModel,
  RelationshipModel,
  UserModel,
} from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";
import _ from "lodash";

export interface IUserFormInitParams {
  getFormData: () => any;
}

const roleOptions = [
  { text: "Admin", value: ROLE.ADMIN },
  { text: "User", value: ROLE.USER },
  { text: "View Only", value: ROLE.READONLY },
];

export interface IUserFormProps {
  user?: UserModel;
  relationships?: RelationshipModel[];
  onInit?: (params: IUserFormInitParams) => void;
  onRelationshipsUpdate?: (params: RelationshipModel[]) => void;
  onClose?: () => void;
  setTouched: any;
  setprojectsTouched: any;
  showConfirmCancel: any;
  setShowConfirmCancel: any;
  create?: boolean;
}

export type IUserForm = {
  first_name: FormControl;
  last_name: FormControl;
  email: FormControl;
};

export interface IProjectAccess extends ProjectModel {
  roles?: ROLE[];
}

const UserForm: FC<IUserFormProps> = ({
  user,
  relationships = [],
  setTouched,
  setprojectsTouched,
  showConfirmCancel,
  setShowConfirmCancel,
  onInit = () => {},
  onRelationshipsUpdate = () => {},
  onClose = () => {},
  create = false,
}) => {
  const organization = useSelector(getActiveOrganization);
  const [form] = useForm<IUserForm>({
    first_name: new FormControl("", [Validators.required()]),
    last_name: new FormControl("", [Validators.required()]),
    email: new FormControl("", [Validators.required(), Validators.email()]),
  });
  const [projects, setProjects] = useState<IProjectAccess[]>([]);
  const [initialForm, setInitialForm] = useState(form.getFormData());
  const [initialProjects, setInitialProjects] = useState(projects);

  let isTheSame = _.isEqual(initialForm, form.getFormData());
  let isProjectsTheSame = _.isEqual(projects, initialProjects);

  setTouched(!isTheSame);
  setprojectsTouched(!isProjectsTheSame);

  useEffect(() => {
    if (user) {
      form.patch({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      });
      initialForm.first_name = user.first_name;
      initialForm.last_name = user.last_name;
      initialForm.email = user.email;
    }
  }, [user]);

  useEffect(() => {
    if (!organization) {
      setProjects([]);
      setInitialProjects([]);
      return;
    }

    if (user) {
      UserService.findOrganizations(user.uuid)
        .then((data) => {
          onRelationshipsUpdate(data.map((project) => project.relationship));
          (data as IProjectAccess[]).forEach(
            (item) => (item.roles = item.relationship?.roles || [])
          );
          setProjects(data);
          setInitialProjects(data);
        })
        .catch((err) => {
          onRelationshipsUpdate([]);
          setProjects([]);
          setInitialProjects([]);
          ToastService.showHttpError(err, "Loading projects failed");
        });
    } else {
      ProjectService.search(organization.uuid).then(
        (data: IProjectAccess[]) => {
          let main_org_copy = JSON.parse(JSON.stringify(organization));
          data = [main_org_copy, ...data];
          data.forEach((item) => (item.roles = []));

          onRelationshipsUpdate(
            data.map(
              (project) =>
                new RelationshipModel({
                  org_uuid: project.uuid,
                  roles: [],
                })
            )
          );
          setProjects(data);
          setInitialProjects(data);
        },
        (err) => {
          onRelationshipsUpdate([]);
          setProjects([]);
          setInitialProjects([]);
          ToastService.showHttpError(err, "Loading projects failed");
        }
      );
    }
  }, [organization, user]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return {
        ...form.getFormData(),
      };
    }
    return null;
  }, [form, projects]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  const onProjectRoleChange = (
    i: number,
    project: IProjectAccess,
    role: ROLE
  ) => {
    let newRoles = [role];
    const newProject: IProjectAccess = new ProjectModel({ ...project });

    if (JSON.stringify(project.roles) === JSON.stringify(newRoles))
      newProject.roles = [];
    else newProject.roles = newRoles;

    projects[i] = newProject;
    setProjects(projects);

    relationships[i] = new RelationshipModel({
      org_uuid: newProject.uuid,
      roles: newProject.roles,
    });
    onRelationshipsUpdate([...relationships]); // WTF? I need to make an array copy for this to work
  };

  return (
    <Form formGroup={form}>
      <div className="p-6">
        <div className="flex flex-row space-x-12">
          <Input
            control={form.controls.first_name}
            fullWidth
            label="Name"
            labelClass="text-md font-semibold"
            placeholder="Your name"
          />
          <Input
            control={form.controls.last_name}
            fullWidth
            label="Surname"
            labelClass="text-md font-semibold"
            placeholder="Your surname"
          />
        </div>
        <Input
          control={form.controls.email}
          fullWidth
          containerClass="col-span-2"
          label="Email"
          labelClass="text-md font-semibold"
          placeholder="Email of the new user you'd like to add to the space"
        />
        <div className="font-semibold pb-2">Project Access</div>
        <div>
          {projects.map((project, i) => (
            <div key={i} className="flex flex-row p-2 justify-between">
              <div>{project.name}</div>
              <div className="pr-55">
                <Chips key={i}>
                  {roleOptions.map((option, j) => (
                    <Chip
                      key={j}
                      containerClass="mr-2 mb-0"
                      className="px-3 py-1.5"
                      active={project.roles.includes(option.value)}
                      onClick={() =>
                        onProjectRoleChange(i, project, option.value)
                      }
                      disabled={
                        !create &&
                        ((project.relationship?.is_owner &&
                          option.value === ROLE.USER) ||
                          (project.relationship?.is_owner &&
                            option.value === ROLE.READONLY))
                      }
                    >
                      {option.text}
                    </Chip>
                  ))}
                </Chips>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showConfirmCancel && (
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={
            <p className="text-base">
              You have <span className="font-bold">unsaved changes.</span> Are
              you sure you would like to cancel and close this confirmation
              window?
            </p>
          }
          onClose={onClose}
        />
      )}
    </Form>
  );
};

export default UserForm;
