import React, {FC} from "react";
import {Modal} from "../../components";
import {FullAudioPlayer} from "../FullAudioPlayer";

interface IRecordPlayerModalProps {
  name: string;
  src: string;
  onClose: () => void;
}

export const RecordPlayerModal: FC<IRecordPlayerModalProps> = ({
  name,
  src,
  onClose,
}) => {
  return (
    <Modal
      title="Play Recording"
      onClose={onClose}
    >
      <div className="flex items-center border-b border-gray-f4 pb-3">
        <div className="text-blue-dark font-semibold text-sm px-1">Call Recording</div>
        <div className="flex items-center ml-auto">
          <i className="far fa-circle text-blue text-sm" />
          <span className="text-13p ml-2">Caller</span>
        </div>
        <div className="flex items-center ml-8">
          <i className="far fa-circle text-primary text-sm" />
          <span className="text-13p ml-2">Target</span>
        </div>
      </div>

      <FullAudioPlayer name={name} src={src} stereo />
    </Modal>
  );
};
