import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import Profile from "./Profile";
import UsersRouting from "./Users/routing";
import ProjectsRouting from "./Projects/routing";
import BillingInfoRouting from "./BillingInfo/routing";
import InvoicesRouting from "./Invoices/routing";
import BlockedNumbersRouting from "./BlockedNumbers/routing";
import BlockedKeywordsRouting from "./BlockedKeywords/routing";
import AudioFilesRouting from "./AudioFiles/routing";
import TagsRouting from "./Tags/routing";
import IntegrationsRouting from "./Integrations/routing";
import TwoFactorAuthentication from "./TwoFactorAuthentication";

const SettingsRouting = () => (
  <Switch>
    <Redirect exact path={ROUTES.SETTINGS.PREFIX} to={ROUTES.SETTINGS.INDEX} />
    <Route exact path={ROUTES.SETTINGS.PROFILE} component={Profile} />
    <Route path={ROUTES.SETTINGS.USERS.PREFIX} component={UsersRouting} />
    <Route path={ROUTES.SETTINGS.PROJECTS.PREFIX} component={ProjectsRouting} />
    <Route
      path={ROUTES.SETTINGS.BILLING.PREFIX}
      component={BillingInfoRouting}
    />
    <Route path={ROUTES.SETTINGS.INVOICES.PREFIX} component={InvoicesRouting} />
    <Route
      path={ROUTES.SETTINGS.BLOCKED_NUMBERS.PREFIX}
      component={BlockedNumbersRouting}
    />
    <Route
      path={ROUTES.SETTINGS.BLOCKED_KEYWORDS.PREFIX}
      component={BlockedKeywordsRouting}
    />
    <Route
      path={ROUTES.SETTINGS.AUDIO_FILES.PREFIX}
      component={AudioFilesRouting}
    />
    <Route
      path={ROUTES.SETTINGS.INTEGRATIONS.PREFIX}
      component={IntegrationsRouting}
    />
    <Route path={ROUTES.SETTINGS.TAGS} component={TagsRouting} />
    <Route path={ROUTES.SETTINGS.TFA} component={TwoFactorAuthentication} />
  </Switch>
);

export default SettingsRouting;
