import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../../constants";
import BlockedKeywords from "./index";
import BlockedKeywordCreate from "./BlockedKeywordCreate";
import BlockedKeywordEdit from "./BlockedKeywordEdit";

const BlockedKeywordsRouting = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_KEYWORDS.CREATE}
      component={BlockedKeywordCreate}
    />
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_KEYWORDS.EDIT}
      component={BlockedKeywordEdit}
    />
    <Route
      exact
      path={ROUTES.SETTINGS.BLOCKED_KEYWORDS.PREFIX}
      component={BlockedKeywords}
    />
  </Switch>
);

export default BlockedKeywordsRouting;
