import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Card, Modal } from "../../../../components";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import ApiTokenForm, { IApiTokenFormInitParams } from "../ApiTokenForm";
import { ApiTokenModel } from "../../../../utils/types";
import {
  ApiTokenService,
  MessageBoxService,
  ToastService,
} from "../../../../services";
import { ConfirmationModal } from "../../../../parts";
import { getActiveOrganization } from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../../constants";

const ApiTokenEdit = () => {
  const organization = useSelector(getActiveOrganization);
  const [editForm, setEditForm] = useState<IApiTokenFormInitParams>({
    getFormData: () => null,
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [permissionsTouched, setPermissionsTouched] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { apiTokenId } = useParams<{
    apiTokenId: string;
  }>();
  const [apiTokenData, setApiTokenData] = useState<ApiTokenModel>(null);

  const history = useHistory();

  const navigateBack = () => {
    history.push(ROUTES.DEVELOPER.API_TOKENS.INDEX);
  };

  useEffect(() => {
    if (apiTokenId && organization?.uuid) {
      ApiTokenService.find(organization.uuid, apiTokenId)
        .then((fetchedApiToken) => {
          setApiTokenData(fetchedApiToken);
        })
        .catch((error) => {
          ToastService.showHttpError(error, "Fetching API token failed");
        });
    }
  }, [apiTokenId, organization?.uuid]);

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      ApiTokenService.update(organization.uuid, apiTokenId, {
        ...data,
        org_uuid: organization.uuid,
      })
        .then((data) => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> saved your
              changes.
            </>
          );
          navigateBack();
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Updating api token failed");
        });
    }
  };

  const close = (value?: any) => {
    if (value) {
      navigateBack();
    } else {
      setShowConfirmation(false);
    }

    if (
      (touched && value === undefined) ||
      (permissionsTouched && value === undefined)
    ) {
      setShowConfirmation(true);
      return;
    }
    if (
      (value === undefined && !touched) ||
      (value === undefined && !permissionsTouched)
    ) {
      navigateBack();
    }
  };

  const onDeleteToken = () => {
    MessageBoxService.confirm({
      type: "passcode",
      title: "Delete confirmation",
      message: (
        <>
          Are you sure you want to delete this{" "}
          <span className="font-semibold">API TOKEN</span>?
        </>
      ),
      passcode: "Delete",
    }).then((result) => {
      if (result) {
        ApiTokenService.delete(organization.uuid, apiTokenId)
          .then(() => {
            ToastService.success(
              <>
                <span className="text-success mr-1">Successfully</span> deleted.
              </>
            );
            navigateBack();
          })
          .catch((err) => {
            ToastService.showHttpError(err, "Deleting api token failed");
          });
      }
    });
  };

  return (
    <>
      <div className="relative z-100 w-full h-full flex flex-col">
        <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
          <div className="font-semibold text-lg uppercase pt-3 pb-2">
            Edit Api Token
          </div>
          <CloseCircleIcon
            color="white"
            size={32}
            className="cursor-pointer"
            onClick={() => setShowConfirmation(true)}
          />
        </div>

        <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
          <Card>
            <div className=" mx-auto my-6">
              <ApiTokenForm
                apiToken={apiTokenData}
                onInit={setEditForm}
                setTouched={setTouched}
                setPermissionsTouched={setPermissionsTouched}
                onClose={() => navigateBack()}
              />
            </div>
          </Card>
        </div>
        <div className="flex justify-between p-6 space-x-4">
          <Button
            data-cy="delete-token"
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDeleteToken}
          >
            Delete Token
          </Button>
          <Button
            data-cy="save-api-token"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={
            <p className="text-base">
              You have <span className="font-bold">unsaved changes.</span> Are
              you sure you would like to cancel and close this confirmation
              window?
            </p>
          }
          onClose={close}
        />
      )}
    </>
  );
};

export default ApiTokenEdit;
