import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import ApiTokens from "./ApiTokens";
import ApiTokenCreate from "./ApiTokens/ApiTokenCreate";
import DeveloperDocumentation from "./Documentation";
import { Issues } from "./Issues";
import Webhooks from "./Webhooks";
import { WebhookCreate } from "./Webhooks/WebhookCreate";
import { WebhookEdit } from "./Webhooks/WebhookEdit";
import { ErrorManagement } from "./ErrorManagement";
import { IssueDetails } from "./Issues/IssueDetails";
import ApiTokenEdit from "./ApiTokens/ApiTokenEdit";

const DeveloperRouting = () => (
  <Switch>
    <Redirect
      exact
      from={ROUTES.DEVELOPER.PREFIX}
      to={ROUTES.DEVELOPER.INDEX}
    />
    <Route
      path={ROUTES.DEVELOPER.DOCUMENTATION}
      component={DeveloperDocumentation}
    />
    <Route
      path={ROUTES.DEVELOPER.API_TOKENS.CREATE}
      component={ApiTokenCreate}
    />
    <Route path={ROUTES.DEVELOPER.API_TOKENS.EDIT} component={ApiTokenEdit} />
    <Route path={ROUTES.DEVELOPER.API_TOKENS.INDEX} component={ApiTokens} />
    <Route
      path={ROUTES.DEVELOPER.ERROR_MANAGEMENT.INDEX}
      component={ErrorManagement}
    />
    <Route path={ROUTES.DEVELOPER.ISSUES.DETAILS} component={IssueDetails} />
    <Route path={ROUTES.DEVELOPER.ISSUES.INDEX} component={Issues} />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.CREATE} component={WebhookCreate} />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.EDIT} component={WebhookEdit} />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.INDEX} component={Webhooks} />
  </Switch>
);

export default DeveloperRouting;
