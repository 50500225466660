import React, { FC, useState } from "react";
import { Button, Card, Modal } from "../../../../components";
import BlockedNumberForm, {
  IBlockedNumberFormInitParams,
} from "../BlockedNumberForm";
import { BlockedNumberModel } from "../../../../utils/types";
import { ToastService } from "../../../../services";
import { CloseCircleIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { useHistory } from "react-router-dom";

const BlockedNumberCreate = () => {
  const history = useHistory();

  const [createForm, setCreateForm] = useState<IBlockedNumberFormInitParams>({
    getFormData: () => null,
  });

  const onClose = (bypass?: boolean) => {
    history.push(ROUTES.SETTINGS.BLOCKED_NUMBERS.INDEX);
  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      BlockedNumberModel.create(data)
        .then((res) => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> blocked
              the number.
            </>
          );
          onClose();
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Create failed.");
        });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Add Blocked Number
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => onClose()}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
        <Card>
          <BlockedNumberForm onChange={setCreateForm} onClose={onClose} />
        </Card>
      </div>
      <div className="flex justify-end p-6 space-x-4">
        <Button
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          onClick={onCreate}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default BlockedNumberCreate;
