import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import UserForm, { IUserFormInitParams } from "../UserForm";
import { Button, Card } from "../../../../components";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { getActiveOrganization } from "../../../../redux/selectors";
import {
  MessageBoxService,
  RelationshipService,
  ToastService,
  UserService,
} from "../../../../services";
import { RelationshipModel, UserModel } from "../../../../utils/types";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
const UserEdit = () => {
  const history = useHistory();
  const organization = useSelector(getActiveOrganization);
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<UserModel>();
  const [relationships, setRelationships] = useState<RelationshipModel[]>([]);
  const [createForm, setCreateForm] = useState<IUserFormInitParams>({
    getFormData: () => null,
  });
  const [touched, setTouched] = useState<boolean>(true);
  // because the projects are separate from the form
  const [projectsTouched, setprojectsTouched] = useState<boolean>(true);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(
      bypass,
      touched,
      projectsTouched,
      setShowConfirmCancel,
      history,
      ROUTES.SETTINGS.USERS.INDEX
    );
  };

  useEffect(() => {
    if (organization) {
      UserService.find(organization.uuid, id)
        .then((data) => {
          setUser(data);
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Loading user failed");
          onClose(true);
        });
    }
  }, [organization, id]);

  const onSave = () => {
    const data = createForm.getFormData();
    if (data) {
      let payload: any = [];
      relationships.forEach((relationship, i) => {
        payload[i] = { uuid: relationship.org_uuid, roles: relationship.roles };
      });

      UserService.update(organization.uuid, id, {
        relationships: payload,
        ...data,
      })
        .then((data) => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> saved your
              changes.
            </>
          );
          onClose(true);
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Updating user failed");
        });
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: "passcode",
      title: "Delete confirmation",
      message: (
        <>
          Are you sure you want to delete this{" "}
          <span className="font-semibold">USER</span>?
        </>
      ),
      passcode: "Delete",
    }).then((result) => {
      if (result) {
        RelationshipService.delete(organization.uuid, user.relationship.uuid)
          .then(() => {
            ToastService.success(
              <>
                <span className="text-success mr-1">Successfully</span> deleted.
              </>
            );
            onClose(true);
          })
          .catch((err) => {
            ToastService.showHttpError(err, "Deleting user failed");
          });
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Edit User
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => onClose()}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
        <Card>
          {user && (
            <UserForm
              user={user}
              relationships={relationships}
              onRelationshipsUpdate={(relationships) =>
                setRelationships(relationships)
              }
              onInit={setCreateForm}
              onClose={onClose}
              setTouched={setTouched}
              setprojectsTouched={setprojectsTouched}
              showConfirmCancel={showConfirmCancel}
              setShowConfirmCancel={setShowConfirmCancel}
            />
          )}
        </Card>
      </div>
      <div className="flex row p-6 justify-end">
        {user && !user.relationship.is_owner && (
          <Button
            data-cy="delete-user"
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Delete User
          </Button>
        )}
        <Button
          className="text-sm rounded-full border border-500-red p-18"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserEdit;
