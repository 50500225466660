import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import { Card } from "@mui/material";
import { Button, Input } from "../../../../components";
import BlockedKeywordForm, {
  IBlockedKeywordFormInitParams,
} from "../BlockedKeywordForm";

import {
  BLOCKED_KEYWORD_CHANNEL_VALUES,
  BLOCKED_KEYWORD_TYPE,
  BLOCKED_NUMBER_CHANNEL,
} from "../../../../utils/enums";
import { MessageBoxService, ToastService } from "../../../../services";
import { BlockedKeywordModel } from "../../../../utils/types";

const BlockedKeywordEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // #TODO ADD BLOCKED KEYWORD SERVICE

  const blockedKeyword = new BlockedKeywordModel({
    uuid: "19",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: BLOCKED_KEYWORD_CHANNEL_VALUES,
    blockedBy: "Marisha van der Merwe",
  });

  const [editForm, setEditForm] = useState<IBlockedKeywordFormInitParams>({
    getFormData: () => null,
  });

  const onClose = (bypass?: boolean) => {
    history.push(ROUTES.SETTINGS.BLOCKED_KEYWORDS.INDEX);
  };

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      ToastService.success(
        <>
          <span className="text-success mr-1">Successfully</span> saved your
          changes.
        </>
      );
      onClose();
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: "passcode",
      title: "Delete confirmation",
      message: <>Are you sure you want to remove this keywords from spam?</>,
      passcode: "Delete",
    }).then((result) => {
      if (result) {
        ToastService.success(
          <>
            <span className="text-success mr-1">Successfully</span> removed from
            spam.
          </>
        );
        onClose();
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Edit Blocked Keyword(s)
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => onClose()}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
        <Card className="p-10">
          <BlockedKeywordForm
            blockedKeyword={blockedKeyword}
            onInit={setEditForm}
            onClose={onClose}
          />
        </Card>
      </div>
      <div className="flex justify-between p-6 space-x-4">
        <Button
          className="bg-blue rounded-full text-white px-6 py-2 text-sm"
          onClick={onDelete}
        >
          UNBLOCK KEYWORD(S)
        </Button>
        <Button
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          onClick={onSave}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default BlockedKeywordEdit;
